const UpSvg = () => (
  <svg
    width='54'
    height='54'
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <path d='M9 53L0.999999 53L1 45' stroke='white' strokeLinecap='square' />
      <path d='M53 45L53 53L45 53' stroke='white' strokeLinecap='square' />
      <path d='M9 1L0.999999 1L1 9' stroke='white' strokeLinecap='square' />
      <path
        d='M53 9L53 0.999999L45 0.999999'
        stroke='white'
        strokeLinecap='square'
      />
    </g>
    <path
      d='M27 37V18M27 18L19 25.9677M27 18L35 25.9677'
      stroke='white'
      strokeWidth='2'
    />
  </svg>
)

export default UpSvg
