import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    isStakeModalOpen: false,
    isHelpModalOpen: false,
    isHelpModalWasClosed: false,
    isWalletModalOpen: false,
    isWarningModalOpen: false,
  },
  reducers: {
    setIsStakeModalOpen: (state, action) => {
      state.isStakeModalOpen = action.payload;
    },
    setIsHelpModalOpen: (state, action) => {
      state.isHelpModalOpen = action.payload;

      if (action.payload === false) {
        state.isHelpModalWasClosed = true;
      }
    },
    setIsWalletModalOpen: (state, action) => {
      state.isWalletModalOpen = action.payload;
    },
    setIsWarningModalOpen: (state, action) => {
      state.isWarningModalOpen = action.payload;
    },
  },
});

export const { setIsStakeModalOpen } = modalsSlice.actions;
export const { setIsHelpModalOpen } = modalsSlice.actions;
export const { setIsWalletModalOpen } = modalsSlice.actions;
export const { setIsWarningModalOpen } = modalsSlice.actions;

export const selectIsStakeModalOpen = (state: RootState) =>
  state.modals.isStakeModalOpen;
export const selectIsHelpModalOpen = (state: RootState) =>
  state.modals.isHelpModalOpen;
export const selectIsHelpModalWasClosed = (state: RootState) =>
  state.modals.isHelpModalWasClosed;
export const selectIsWalletModalOpen = (state: RootState) =>
  state.modals.isWalletModalOpen;
export const selectIsWarningModalOpen = (state: RootState) =>
  state.modals.isWarningModalOpen;

export default modalsSlice.reducer;
