import style from './BurgerBtn.module.scss'

// img import
import BurgerBgSvg from '../../assets/burgerBg'

interface BurgerBtnInterface {
  className?: string
  onClick?: () => void
  active?: boolean
}

const BurgerBtn = ({ className, onClick, active }: BurgerBtnInterface) => {
  return (
    <button
      onClick={onClick}
      className={`
  ${style.burger} 
  ${className}
  ${active ? style._active : ''}
  `}
    >
      <span></span>
      <span></span>
      <span></span>
      <div className={style.burger__bg}>{BurgerBgSvg()}</div>
    </button>
  )
}

export default BurgerBtn
