import { ImgWebp } from '@helpers/imgwebp';
import style from './Header.module.scss';
// import Button from '../Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import { useState } from 'react';
import BurgerBtn from '@pages/Dashboard/components/Header/components/BurgerBtn/BurgerBtn';
import { Link } from 'react-router-dom';

// img imports
import logoImg from '@dashboard-assets/img/header/logo.png';
import logoImg2x from '@dashboard-assets/img/header/logo@2x.png';
import logoImgWebp from '@dashboard-assets/img/header/logo.webp';
import arrow from '../Footer/assets/arrow';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const toggleMenu = () => {
    const body = document.querySelector('body');

    if (body?.classList.contains('_lock')) {
      body?.classList.remove('_lock');
    } else {
      body?.classList.add('_lock');
    }

    setIsBurgerOpen(!isBurgerOpen);
  };

  const toPage = () => {
    if (isMobile) {
      toggleMenu();
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <header className={style.header}>
      <div className='container'>
        <div className={style.headerBody}>
          <Link to='' className={style.headerBody__logo}>
            <ImgWebp src={logoImg} src2x={logoImg2x} srcWebp={logoImgWebp} />
          </Link>
          {isMobile ? (
            <div
              className={`${style.mobileMenu} ${
                isBurgerOpen ? style._active : ''
              }`}
            >
              <div className={style.headerBody__menu}>
                <Link
                  onClick={toPage}
                  to='parapad'
                  className={style.headerBody__menuItem}
                >
                  Parapad
                </Link>
                <Link
                  onClick={toPage}
                  to='roadmap'
                  className={style.headerBody__menuItem}
                >
                  Roadmap
                </Link>
                <Link
                  onClick={toPage}
                  to='tocenomic'
                  className={style.headerBody__menuItem}
                >
                  Tokenomics
                </Link>
                <Link
                  onClick={toPage}
                  to='team'
                  className={style.headerBody__menuItem}
                >
                  Team
                </Link>
                <Link
                  onClick={toPage}
                  to='dashboard/overview'
                  className={style.headerBody__menuItem}
                >
                  Dashboard
                </Link>
                {/* <a
                  onClick={toPage}
                  rel='noreferrer'
                  target='_blank'
                  href='https://nupara.io/'
                >
                  <Button
                    title='Merch'
                    sm
                    className={style.headerBody__menuBtn}
                  />
                </a> */}
              </div>

              <div className={style.footerBody__links}>
                <div className={style.footerBody__linksContent}>
                  <div className={style.col}>
                    <a
                      rel='noreferrer'
                      target={'_blank'}
                      href='https://instagram.com/paradoxmeta.ios'
                      className={style.footerBody__linksItem}
                    >
                      instagram {arrow()}
                    </a>
                    <a
                      rel='noreferrer'
                      target={'_blank'}
                      href='https://t.me/+2HsutcKbnUFkN2U0'
                      className={style.footerBody__linksItem}
                    >
                      Telegram {arrow()}
                    </a>
                    <a
                      rel='noreferrer'
                      target={'_blank'}
                      href='https://medium.com/@ParadoxMeta.io'
                      className={style.footerBody__linksItem}
                    >
                      Medium {arrow()}
                    </a>
                  </div>
                  <div className={style.col}>
                    <a
                      rel='noreferrer'
                      target={'_blank'}
                      href='https://discord.com/invite/paradoxmetaverseofficial'
                      className={style.footerBody__linksItem}
                    >
                      Discord {arrow()}
                    </a>
                    <a
                      rel='noreferrer'
                      target={'_blank'}
                      href='https://twitter.com/paradoxmeta_io'
                      className={style.footerBody__linksItem}
                    >
                      Twitter {arrow()}
                    </a>
                  </div>
                </div>
                <div className={style.footerBody__linksBottom}>
                  <a
                    href='https://paradox-studios-ltd.gitbook.io/paradox-whitepaper/paradox-metaverse/always-do-your-research'
                    target={'_blank'}
                    rel='noreferrer'
                    className={style.footerBody__linksItem}
                  >
                    / White Paper
                  </a>
                  <div className={style.footerBody__linksItem}>
                    / With Paradox Labs Inc.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={style.headerBody__menu}>
              <Link
                onClick={toPage}
                to='parapad'
                className={style.headerBody__menuItem}
              >
                Parapad
              </Link>
              <Link
                onClick={toPage}
                to='roadmap'
                className={style.headerBody__menuItem}
              >
                Roadmap
              </Link>
              <Link
                onClick={toPage}
                to='tocenomic'
                className={style.headerBody__menuItem}
              >
                Tokenomics
              </Link>
              <Link
                onClick={toPage}
                to='team'
                className={style.headerBody__menuItem}
              >
                Team
              </Link>
              <Link
                onClick={toPage}
                to='dashboard/overview'
                className={style.headerBody__menuItem}
              >
                Dashboard
              </Link>
              {/* <a rel='noreferrer' target='_blank' href='https://nupara.io/'>
                <Button
                  title='Merch'
                  sm
                  className={style.headerBody__menuBtn}
                />
              </a> */}
            </div>
          )}

          {isMobile ? (
            <BurgerBtn
              onClick={() => toggleMenu()}
              className={style.headerBody__burger}
              active={isBurgerOpen}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
