import style from './TgWidget.module.scss';

// img imports
import img from './DarwinDiscord.png';

const TgWidget = () => {
  return (
    <a
      href='https://discord.gg/paradoxmetaverseofficial'
      className={style.widget_wrapper}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={style.widget_icon}>
        <img className={style.widget_img} alt='' src={img} />
      </div>
      <div className={style.widget_text}>
        <span>Join Our Discord!</span>
        <br />
        <span>Let's talk!</span>
      </div>
    </a>
  );
};

export default TgWidget;
