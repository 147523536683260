import Button from '../Button/Button';
import style from './Footer.module.scss';

// img imports
import bg from './assets/bg.png';
import arrow from './assets/arrow';
import logo from '../../assets/header/logo.png';
import up from './assets/up';

const Footer = () => {
  const toUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={style.footer}>
      <div className={style.footer__bg}>
        <img src={bg} alt='' />
      </div>
      <div className='container'>
        <div className={style.footerBody}>
          <div className={style.footerBody__subs}>
            <div className={style.footerBody__subsTitle}>
              You want to know more about <span>the metaverse?</span>
            </div>
            <div className={style.footerBody__subsText}>
              Sign up to our newsletter and receive our new research, portfolio
              news and content direct to your inbox.
            </div>
            <form className={style.footerBody__subsForm}>
              <input type='text' placeholder='Subscribe to our newsletter' />
              <Button title='Subscribe' />
            </form>
          </div>
          <div className={style.footerBody__links}>
            <div className={style.footerBody__linksContent}>
              <div className={style.col}>
                <a
                  rel='noreferrer'
                  target={'_blank'}
                  href='https://instagram.com/paradoxmeta.ios'
                  className={style.footerBody__linksItem}
                >
                  instagram {arrow()}
                </a>
                <a
                  rel='noreferrer'
                  target={'_blank'}
                  href='https://t.me/+2HsutcKbnUFkN2U0'
                  className={style.footerBody__linksItem}
                >
                  Telegram {arrow()}
                </a>
                <a
                  rel='noreferrer'
                  target={'_blank'}
                  href='https://medium.com/@ParadoxMeta.io'
                  className={style.footerBody__linksItem}
                >
                  Medium {arrow()}
                </a>
              </div>
              <div className={style.col}>
                <a
                  rel='noreferrer'
                  target={'_blank'}
                  href='https://discord.com/invite/paradoxmetaverseofficial'
                  className={style.footerBody__linksItem}
                >
                  Discord {arrow()}
                </a>
                <a
                  rel='noreferrer'
                  target={'_blank'}
                  href='https://twitter.com/paradoxmeta_io'
                  className={style.footerBody__linksItem}
                >
                  Twitter {arrow()}
                </a>
              </div>
            </div>
            <div className={style.footerBody__linksBottom}>
              <a
                href='https://paradox-studios-ltd.gitbook.io/paradox-whitepaper/paradox-metaverse/always-do-your-research'
                target={'_blank'}
                rel='noreferrer'
                className={style.footerBody__linksItem}
              >
                / White Paper
              </a>
              <div className={style.footerBody__linksItem}>
                / With Paradox Labs Inc.
              </div>
            </div>
          </div>
        </div>
        <div className={style.footerBottom}>
          <div className={style.footerBottom__rights}>
            Copyright © theparadox.studio 2022. All rights reserved.
          </div>
          <div className={style.footerBottom__logo}>
            <img src={logo} alt='' />
          </div>
          <div onClick={toUp} className={style.footerBottom__up}>
            {up()}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
