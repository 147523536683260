const BurgerBgSvg = () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask id='path-1-inside-1_1_776' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 0H60V48L48 60H0V12L12 0Z'
      />
    </mask>
    <path
      d='M60 0H62V-2H60V0ZM12 0V-2H11.1716L10.5858 -1.41421L12 0ZM60 48L61.4142 49.4142L62 48.8284V48H60ZM48 60V62H48.8284L49.4142 61.4142L48 60ZM0 60H-2V62H0V60ZM0 12L-1.41421 10.5858L-2 11.1716V12H0ZM60 -2H12V2H60V-2ZM62 48V0H58V48H62ZM49.4142 61.4142L61.4142 49.4142L58.5858 46.5858L46.5858 58.5858L49.4142 61.4142ZM0 62H48V58H0V62ZM-2 12V60H2V12H-2ZM10.5858 -1.41421L-1.41421 10.5858L1.41421 13.4142L13.4142 1.41421L10.5858 -1.41421Z'
      fill='white'
      fillOpacity='0.2'
      mask='url(#path-1-inside-1_1_776)'
    />
  </svg>
)

export default BurgerBgSvg
