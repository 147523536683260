const ArrowSvg = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      opacity='0.6'
      d='M1 15L15 1M15 1V15M15 1H1'
      stroke='white'
      strokeWidth='2'
    />
  </svg>
)

export default ArrowSvg
