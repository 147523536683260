import React, { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import Home from '@pages/Home/Home';

const Dashboard = lazy(() => import('@pages/Dashboard/Dashboard'));
const Parapad = lazy(() => import('@pages/Parapad/Parapad'));

function App(): JSX.Element {
  const isDashboard = useLocation()
    .pathname.split('/')
    .some((item) => item === 'dashboard');
  const isParapad = useLocation()
    .pathname.split('/')
    .some((item) => item === 'parapad');

  if (isDashboard) {
    return (
      <Suspense fallback={<div className=''></div>}>
        <Dashboard />
      </Suspense>
    );
  } else if (isParapad) {
    return (
      <Suspense fallback={<div className=''></div>}>
        <Parapad />
      </Suspense>
    );
  } else {
    return <Home />;
  }
}

export default App;
