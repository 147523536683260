import style from './Button.module.scss';

interface ButtonInterface {
  title: string;
  className?: string;
  disabled?: boolean;
  href?: string;
  sm?: boolean;
  onClick?: () => void;
}

const Button = ({
  title,
  className,
  sm,
  disabled,
  href,
  onClick,
}: ButtonInterface) => {
  if (href) {
    return (
      <a
        href={href}
        rel='noreferrer'
        target='_blank'
        onClick={onClick}
        className={`${style.button} ${sm ? style._sm : ''} ${className}`}
      >
        {title}
      </a>
    );
  } else {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={`${style.button} ${sm ? style._sm : ''} ${className}`}
      >
        {title}
      </button>
    );
  }
};

export default Button;
