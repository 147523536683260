import style from './Home.module.scss';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import TgWidget from './components/TgWidget/TgWidget';

const Main = lazy(() => import('./pages/Main/Main'));
const Team = lazy(() => import('./pages/Team/Team'));
const Tocenomic = lazy(() => import('./pages/Tocenomic/Tocenomic'));
const Roadmap = lazy(() => import('./pages/Roadmap/Roadmap'));

const Home = () => {
  return (
    <div className={`wrapper`}>
      <TgWidget />
      <div className={`content`}>
        <Header />

        <Suspense fallback={<div className=''></div>}>
          <Routes>
            <Route path='' element={<Main />} />
            <Route path='team' element={<Team />} />
            <Route path='tocenomic' element={<Tocenomic />} />
            <Route path='roadmap' element={<Roadmap />} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
